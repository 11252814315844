<template>
  <vue-good-table
    class="position-static"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :sort-options="{enabled: false}"
    :filter-options="{enabled: false}"
    :search-options="{enabled: false}"
    :pagination-options="{enabled: true, perPage: perPage}"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <div
        v-if="props.column.label === 'Name'"
        cols="12"
        md="2"
        class="d-flex vgt-center-align"
      >
        <b-link
          @click="handleClick(props.row)"
        >
          {{ props.formattedRow[props.column.field] }}
        </b-link>
      </div>
    </template>
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { mapGetters } from "vuex";
import { VBToggle, BLink } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import { topicTypes } from "@/models";

export default {
  components: {
    VueGoodTable,
    TablePagination,
    BLink
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => { return {
        items: [],
        total:0
      };}
    },
    perPage: {
      type: Number,
      default: 10,
      required: false
    },
    type: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,
      rows: [],
      total: 0,
    };
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  watch:{
    data() {
      this.rows = this.data.items;
      this.total = this.data.total;
    },
  },
  methods: {
    onPageChange(params) {
      this.$emit('onPageChange', this.type, params);
    },
    handleClick(suggestion) {
      const type = this.type;
      if (type === 'client') {
        if (this.isAdminPortal) {
          this.$router.push({
            name: 'admin-client',
            params: { clientId: suggestion.id },
          });
        }
        return false;
      } else if (type === 'program') {
        this.$router.push({
          name: this.isChampionPortal? 'champion-home': 'admin-program',
          params: { id: suggestion.id },
        });
      } else if (type === 'participant') {
        const userRole = suggestion.user_roles.find(ur => ur.program_id !== null);
        if (userRole) {
          this.$router.push({
            name: 'champion-program-participant',
            params: { id: userRole.program_id, participantId: suggestion.id},
          });
        }
      } else if (type === 'champion') {
        var userRole = suggestion.user_roles.find(ur => ur.client_id !== null);
        if (userRole && this.isAdminPortal) {
          this.$router.push({
            name: 'admin-client',
            params: { clientId: userRole.client_id},
            query: { id: suggestion.id},
            hash: '#champions'
          });
        } else {
          userRole = suggestion.user_roles.find(ur => ur.program_id !== null);
          if (userRole) {
            this.$router.push({
              name: 'admin-program',
              params: { id: userRole.program_id},
              query: { id: suggestion.id},
              hash: '#champions'
            });
          } else {
            return false;
          }
        }
      } else if (type === 'resource') {
        if (suggestion.is_library && this.isAdminPortal) {
          this.$router.push({
            name: 'admin-resources-list',
            query: {
              id: suggestion.id
            }
          });
        } else {
          this.$router.push({
            name: 'champion-program-resources',
            params: { id: suggestion.programs[0].id },
            query: {
              id: suggestion.id
            }
          });
        }
      } else if (type === 'application') {
        if (suggestion.program_id) {
          this.$router.push({
            name: 'champion-program-application',
            params: { application: suggestion.id, id: suggestion.program_id },
          });
        } else if (this.isAdminPortal) {
          this.$router.push({
            name: 'admin-application',
            params: { application: suggestion.id },
          });
        }
      } else if (type === 'survey') {
        if (suggestion.program_id === null) {
          if (this.isAdminPortal) {
            this.$router.push({
              name: 'admin-survey',
              params: { survey: suggestion.id },
            });
          }
        } else {
          this.$router.push({
            name: this.isChampionPortal? 'champion-program-survey': 'admin-program-survey',
            params: { id: suggestion.program_id, survey: suggestion.id },
          });
        }
      } else if (type === 'communication') {
        if (suggestion.program_id === null) {
          if (this.isAdminPortal) {
            this.$router.push({
              name: 'admin-communications-list',
              query: {
                id: suggestion.id
              }
            });
          }
        } else {
          if (suggestion.is_schedulable) {
            this.$router.push({
              name: 'champion-program-schedules',
              params: { id: suggestion.program_id },
              query: {
                scheduledId: suggestion.id
              }
            });
          } else if (suggestion.type_id === topicTypes.INVITE){
            this.$router.push({
              name: 'champion-program-invites',
              params: { id: suggestion.program_id },
              query: {
                id: suggestion.id
              }
            });
          } else {
            this.$router.push({
              name: 'champion-program-communications',
              params: { id: suggestion.program_id },
              query: {
                id: suggestion.id
              }
            });
          }
        }
      } else if (type === 'training') {
        if (this.isAdminPortal) {
          this.$router.push({
            name: 'admin-training-list',
            query: {
              id: suggestion.id
            }
          });
        } else if(suggestion.program_trainings.length) {
          this.$router.push({
            name: 'champion-program-training-list',
            params: { id: suggestion.program_trainings[0].program_id },
            query: {
              id: suggestion.id
            }
          });
        }
      } else if (type === 'notes') {
        this.$router.push({
          name: 'champion-program-participant',
          params: { id: suggestion.program_id, participantId: suggestion.notable_id },
          hash: "#notes"
        });
      }
    },
  },
  setup() {
    const { total, page } = useAomTableConfig();
    return {
      total, page
    };
  }
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
