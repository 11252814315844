<template>
  <b-card>
    <b-row>
      <b-col sm="6">
        <b-form-group 
          label="Search" 
          label-for="Search"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              id="search"
              v-model="searchQuery"
              placeholder="Search"
              @input="getSearchResult()"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group 
          label="Program" 
          label-for="Program"
        >
          <v-select
            v-model="program_id"
            dir="ltr"
            placeholder="Select a Program"
            :options="championPrograms"
            :reduce="(option) => option.id"
            label="name"
          >
            <template v-slot:option="option">
              {{ option.client.name }} - {{ option.name }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="6">
        <h4>Organisations</h4>
        <search-results
          type="client"
          :data="clients"
          :columns="columns['client']"
          :per-page="perPage"
          @onPageChange="onPageChange"
        />
      </b-col>

      <b-col sm="6">
        <h4>Program</h4>
        <search-results
          type="program"
          :data="programs"
          :columns="columns['program']"
          :per-page="perPage"
          @onPageChange="onPageChange"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="6">
        <h4>Participants</h4>
        <search-results
          type="participant"
          :data="participants"
          :columns="columns['participant']"
          :per-page="perPage"
          @onPageChange="onPageChange"
        />
      </b-col>

      <b-col sm="6">
        <h4>Champion</h4>
        <search-results
          type="champion"
          :data="champions"
          :columns="columns['champion']"
          :per-page="perPage"
          @onPageChange="onPageChange"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="6">
        <h4>Resources</h4>
        <search-results
          type="resource"
          :data="resources"
          :columns="columns['resource']"
          :per-page="perPage"
          @onPageChange="onPageChange"
        />
      </b-col>

      <b-col sm="6">
        <h4>Applications</h4>
        <search-results
          type="application"
          :data="applications"
          :columns="columns['application']"
          :per-page="perPage"
          @onPageChange="onPageChange"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="6">
        <h4>Survey</h4>
        <search-results
          type="survey"
          :data="surveys"
          :columns="columns['survey']"
          :per-page="perPage"
          @onPageChange="onPageChange"
        />
      </b-col>

      <b-col sm="6">
        <h4>Communication</h4>
        <search-results
          type="communication"
          :data="communications"
          :columns="columns['communication']"
          :per-page="perPage"
          @onPageChange="onPageChange"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="6">
        <h4>Training</h4>
        <search-results
          type="training"
          :data="trainings"
          :columns="columns['training']"
          :per-page="perPage"
          @onPageChange="onPageChange"
        />
      </b-col>

      <b-col sm="6">
        <h4>Notes</h4>
        <search-results
          type="notes"
          :data="notes"
          :columns="columns['notes']"
          :per-page="perPage"
          @onPageChange="onPageChange"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormGroup
} from "bootstrap-vue";
import SearchResults from "./SearchResults.vue";
import searchService from "@/services/searchService";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import { mapGetters } from 'vuex';
import vSelect from "vue-select";
import _isEqual from 'lodash/isEqual';

export default {
  name: "AdminSearch",
  components: {
    BRow, BCol, BCard,
    SearchResults,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormInput,
    vSelect
  },
  data() {
    return {
      program_id: null,
      isLoading: false,
      clients: {},
      programs: {},
      participants: {},
      champions: {},
      resources: {},
      applications: {},
      surveys: {},
      communications: {},
      trainings: {},
      notes: {},
      perPage: 10,
      columns: {
        client: [
          {
            label: "Name",
            field: "name",
            width: "5em",
          },
        ],
        program: [
          {
            label: "Name",
            field: "name",
            width: "5em",
          },
        ],
        participant: [
          {
            label: "Name",
            field: "full_name",
            width: "5em",
          },
        ],
        champion: [
          {
            label: "Name",
            field: "full_name",
            width: "5em",
          },
        ],
        resource: [
          {
            label: "Name",
            field: "title",
            width: "5em",
          },
        ],
        application: [
          {
            label: "Name",
            field: "title",
            width: "5em",
          },
        ],
        survey: [
          {
            label: "Name",
            field: "title",
            width: "5em",
          },
        ],
        communication: [
          {
            label: "Name",
            field: "title",
            width: "5em",
          },
        ],
        training: [
          {
            label: "Name",
            field: "course_name",
            width: "5em",
          },
        ],
        notes: [
          {
            label: "Name",
            field: "note_text",
            width: "5em",
          },
        ],
      },
      searchQuery: (this.$route && this.$route.query && this.$route.query.query) || ''
    };
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
    ...mapGetters('programs',['defaultProgramId', 'programList']),
    championPrograms() {
      return this.programList;
    },
  },
  watch: {
    program_id(n, o) {
      if(_isEqual(n, o)) {
        return;
      }
      this.getSearchResult();
    },
  },
  created() {
    this.getSearchResult();
  },
  methods: {
    async getSearchResult(type, params) {
      try {
        if (this.searchQuery) {
          this.isLoading = true;
          const options = {
            search: {
              page: this.page,
              perPage: this.perPage,
            },
            query: this.searchQuery,
            program_id: this.program_id
          };
          if (type) {
            options.type = type;
          }
          if (params?.currentPage) {
            options.search.page = params?.currentPage;
          }
          const response = await this.resolveService(options);
          const { data } = response;
          this.mapSearchResult(data);
        }
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        } 
        this.$toast(makeErrorToast("Search results not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
    mapSearchResult(data) {
      const { clients, programs, participants, champions, resources, applications, surveys, topics, trainings, notes } = data;
      this.clients = clients || this.clients;
      this.programs = programs || this.programs;
      this.participants = participants || this.participants;
      this.champions = champions || this.champions;
      this.resources = resources || this.resources;
      this.applications = applications || this.applications;
      this.surveys = surveys || this.surveys;
      this.communications = topics || this.communications;
      this.trainings = trainings || this.trainings;
      this.notes = notes || this.notes;

      this.resources.items = this.resources?.items?.map(item => {
        return {
          title: item.translations[0].title,
          ...item
        };
      });
      this.applications.items = this.applications?.items?.map(item => {
        return {
          title: item.translations[0].title,
          ...item
        };
      });
      this.surveys.items = this.surveys?.items?.map(item => {
        return {
          title: item.translations[0].title,
          ...item
        };
      });
      this.communications.items = this.communications?.items?.map(item => {
        return {
          title: item.messages[0].subject,
          ...item
        };
      });
    },
    async resolveService(options) {
      return this.isAdminPortal? searchService.getSearchResult(options): searchService.getProgramSearchResult(this.defaultProgramId, options);
    },
    onPageChange(type, params) {
      this.getSearchResult(type, params);
    },
  },
  setup() {
    const { total, page } = useAomTableConfig();
    return {
      total,
      page,
    };
  }
};
</script>
